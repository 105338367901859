import { css, SerializedStyles } from '@emotion/react'
import React, { Fragment, memo } from 'react'
import { UserAvatarAsDialogButton } from '~/modules/auth/components/UserAvatarAsDialogButton'
import { BiMessageAltX, BiErrorCircle } from 'react-icons/bi'
import { useSlack } from '~/modules/SDK/slack/useSlack'
import { useTimeout } from 'react-use'
import useMedia from '~/hooks/useMedia'
import { flex, oneGridArea } from '~/modules/AppLayout/FlexGridCss'
import { BLACK, globalGrey, globalRed, FUTURESAI_THEME } from '~/modules/AppLayout/Colors'
import { fontWeight600, fontWeight100 } from '~/css/font'
import { meCheckHandlerTrialCodeAndProductWeb } from '~/modules/SDK/me/meCheckHandlerTrialCodeAndProductWeb'
import { useMeStore } from '~/modules/SDK/me/useMeStore'
import {
  meCheckHandlerAgentWeb,
  meCheckHandlerAgentWebStock,
} from '~/modules/SDK/me/meCheckHandlerAgentWeb'
import { AppLink } from '~/components/AppLink'
import { createDesktopCss } from '~/css/createDesktopCss'

export const LoginView = memo(function LoginView() {
  const meFirebaseState = useMeStore(state => state.meFirebaseState)
  const meUserState = useMeStore(state => state.meUserState)
  /** Firebase 尚未過驗證且正在驗證 */
  const authorizeProcessing = !meFirebaseState
  /** Firebase 已經過驗證，正在登入期天伺服器 */
  const authorized = meFirebaseState && !meUserState

  const [isReady, cancel] = useTimeout(10000)

  return (
    <Fragment>
      <div css={flex.v.allCenter}>
        <UserAvatarAsDialogButton
          loginAsText={'會員專屬功能，請先登入'}
          rootCSS={css`
            padding: 16px 64px;
            border-radius: 8px;
            font-size: 1.2rem;
            border: 2px solid gray;
            background: ${FUTURESAI_THEME};
            border: ${FUTURESAI_THEME};
            color: white;
          `}
        />

        {/** TODO: refactor this */}
        {isReady() && (
          <div
            onClick={event => {
              cancel()
              globalThis.location.reload()
            }}
          >
            {/*歐歐！網路可能出現問題！請點此重新整理！*/}
          </div>
        )}
        {!isReady() && (authorizeProcessing || authorized) && (
          <p
            css={css`
              text-align: center;
            `}
          >
            {/* <Loading /> */}
          </p>
        )}
      </div>
    </Fragment>
  )
})

export const AuthorizeView = memo(function AuthorizeView(props) {
  const [isReady, cancel] = useTimeout(3000)
  const { isPhone } = useMedia()
  const slack = useSlack()
  if (isReady()) slack.postMessage('試圖登入使用，但權限未開通')

  return (
    <Fragment>
      <div
        css={css`
          z-index: 2;
          font-size: ${isPhone ? 1 : 1.5}rem;
          ${fontWeight600};
          color: ${globalRed.r500};
          ${flex.v.allCenter};
        `}
      >
        <div>
          <BiMessageAltX
            css={css`
              width: 48px;
              height: 48px;
              color: ${globalRed.r500};
            `}
          />
        </div>

        <p>付費會員功能，請洽客服人員開通。</p>
      </div>
    </Fragment>
  )
})

/**
 * I force the end-users has to get login, and get authorized both,
 *
 * If not, I will show end-users the static view, tell him "Hey, man/lady please login first"
 */

const _BaseUserSubscriptionCheck = memo<
  React.PropsWithChildren<{
    rootCSS?: SerializedStyles
    image?: string | undefined
  }>
>(function _BaseUserSubscriptionCheck(props) {
  const meFirebaseState = useMeStore(state => state.meFirebaseState)
  const agentConfig = useMeStore(state => state.agentConfig)
  const legitUser = meCheckHandlerTrialCodeAndProductWeb.useCheck()
  const isDark = agentConfig.theme?.palette.mode === 'dark'
  if (legitUser) return null
  // 沒有 firebaseMe 保證是沒登入，登入 firebase 但沒有訂閱相關商品表示權限不夠。
  const view = !meFirebaseState ? <LoginView /> : !legitUser ? <AuthorizeView /> : null

  return (
    <div
      css={css`
        ${oneGridArea.parentGrid('one')};
        & > * {
          ${oneGridArea.childGridItem('one')};
        }
        height: 100vh;
        width: 100vw;
        position: fixed;
        /* Dialog of Material UI (.MuiDialog-root z-index: 1300)*/
        /* Drawer of Material UI (.MuiDialog-root z-index: 1200)*/
        top: 0;
        left: 0;
        ${props.rootCSS};
      `}
    >
      <div
        css={css`
          background: ${props.image ? `url('${props.image}')` : isDark ? BLACK : globalGrey.g300};
          background-repeat: no-repeat;
          background-size: cover;
          filter: blur(4px) opacity(40%);
        `}
      ></div>
      {view}
    </div>
  )
})

export const FuturesaiVersionUserSubscriptionCheck = memo<
  React.PropsWithChildren<{
    image?: string
  }>
>(function FuturesaiVersionUserSubscriptionCheck(props) {
  const { isPc } = useMedia()
  return (
    <_BaseUserSubscriptionCheck
      image={props.image}
      rootCSS={css`
        ${isPc
          ? 'width: calc(100vw - 196px); left:196px;'
          : 'width: 100%; height:calc(100% - 160px); top:160px;'}
      `}
    />
  )
})

/** -----------以下是投顧版----------- */

export const ICAuthorizeView = memo(function ICAuthorizeView(props) {
  const [isReady, cancel] = useTimeout(3000)

  const slack = useSlack()
  if (isReady()) slack.postMessage('試圖登入使用，但權限未開通')
  const { meUserState } = useMeStore()

  const userCode = meUserState?.code?.toString()
  const userInfo = (
    <UserAvatarAsDialogButton
      rootCSS={css`
        padding: 32px 32px;
        border-radius: 10px;
      `}
    />
  )
  return (
    <Fragment>
      <div
        css={css`
          ${flex.v.allCenter};
          background-color: rgba(50, 50, 50, 0.65);
          border-radius: 5px;
          padding: 20px;
        `}
      >
        <div>
          <BiErrorCircle
            css={css`
              width: 50px;
              height: 50px;
              ${fontWeight100};
              color: ${globalRed.r500};
            `}
          />
        </div>
        <div
          css={css`
            font-size: 1rem;
            ${fontWeight600};
            color: ${globalRed.r500};
          `}
        >
          付費會員功能，請洽客服人員開通。
          <div
            css={css`
              ${flex.v.allCenter};
              height: 35px;
              color: #ffffff;
              background-color: #aa0000;
              border-radius: 6px;
              margin-top: 5px;
            `}
          >
            會員代碼 : {userCode}
          </div>
          <div
            css={css`
              ${flex.h.allCenter};
              color: #ffffff;
              background-color: #111111;
              border-radius: 6px;
              margin-top: 5px;
            `}
          >
            登入資訊{userInfo}
          </div>
        </div>
      </div>
    </Fragment>
  )
})

export const ICUserSubscriptionCheckAsFullscreen = memo<
  ReactProps<{
    /**
     * 是否強制顯示？
     *
     * - `true`：stateless。由外部控制「要」顯示本組件。
     * - `false`：stateless。由外部控制「不」顯示本組件。
     * - 預設：stateful。由 useMeStore 在內部判定。
     */
    show?: boolean
  }>
>(function ICUserSubscriptionCheckAsFullscreen(props) {
  const { meFirebaseState } = useMeStore()
  const isWebStock = meCheckHandlerAgentWebStock.useCheck()
  const isWeb = meCheckHandlerAgentWeb.useCheck()

  const legitUser = typeof props.show === 'boolean' ? !props.show : isWebStock || isWeb

  if (legitUser) return null
  // 沒有 firebaseMe 保證是沒登入，登入 firebase 但沒有訂閱相關商品表示權限不夠。
  const view = !meFirebaseState ? <LoginView /> : !legitUser ? <ICAuthorizeView /> : null

  return (
    <div
      className={props.className}
      css={css`
        position: fixed;
        ${flex.v.allCenter};
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.9);
        z-index: 1300;
      `}
    >
      {view}
    </div>
  )
})

/** 此頁在投顧版主畫面中顯示 */
export const InvestmentConsultantSubscriptionCheck = memo<
  React.PropsWithChildren<{
    /**
     * 是否強制顯示？
     *
     * - `true`：stateless。由外部控制「要」顯示本組件。
     * - `false`：stateless。由外部控制「不」顯示本組件。
     * - 預設：stateful。由 useMeStore 在內部判定。
     */
    show?: boolean
    /** 用於電腦保留 SideBar 寬度 投顧版通常介於 300px~350px */
    width?: number
    /** 用於手機保留 TopBar 高度 建議 50px~90px */
    height?: number
  }>
>(function InvestmentConsultantSubscriptionCheck(props) {
  return <ICUserSubscriptionCheckAsFullscreen show={props.show} />
})

/** 此頁在投顧版 Side Bar 中顯示 */
export const InvestmentConsultantNotSubscriptionForSidebar = memo<
  React.PropsWithChildren<{
    darkThem?: true
    image?: string
    /** 用於電腦保留 SideBar 寬度 投顧版通常介於 300px~350px */
    desktopWidth?: number
    mobileWidth?: number
    height?: number
  }>
>(function InvestmentConsultantNotSubscriptionForSidebar(props) {
  return (
    <div
      css={css`
        ${flex.h.allCenter};
        color: ${props.darkThem === true ? '#ffffff' : '#202020'};
        background-color: ${props.darkThem === true ? '#202020' : '#ffffff'};
        width: ${props.mobileWidth ?? 100}%;
        height: ${props.height ?? 90}%;
        ${createDesktopCss(css`
          width: ${props.desktopWidth ?? 300}px;
          height: ${props.height ?? 90}%;
        `)}
      `}
    >
      <div
        css={css`
          ${flex.v.allCenter};
          width: 100%;
          height: 100%;
          font-size: 16px;
        `}
      >
        <div>尚未登入或未開通權限</div>
      </div>
    </div>
  )
})
